import { Store } from '@ngrx/store';
import {
  InterfacePost,
  OrderformConfig,
  OrderformGlobalSettings,
  OrderformOrderState,
  OrderformOrderStateMessages,
  OrderformSelectedPlan,
  OrderformSettingsState,
  OrderFormValidationResult,
  OrderformValidationState,
} from '../ngrx/orderform.models';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { OrderformPartialState } from '../ngrx/orderform.reducer';
import {
  getOrder,
  getOrderformConfig,
  getOrderformGlobalSettings,
  getOrderformMessages,
  getOrderformPaymentPlanList,
  getOrderformPending,
  getOrderformSettings,
  getOrderformState,
  getOrderformValidation,
  getOrderItems,
  getOrderPaymentPlanId,
  getOrderPaymentUrl,
  getOrderPreSelectedPaymentPlanId,
  getOrderSelectedPaymentPlan,
} from '../ngrx/orderform.selectors';
import * as OrderformActions from '../ngrx/orderform.actions';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { ToasterService, ToastItemConfig } from '@digistore/ds24-ui/toaster';

import { AdvancedEventEmitter } from '../misc/advanced-event-emitter';
import { formValidationFailedBeforeSubmit } from '../ngrx/orderform.actions';

declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class OrderformFascade {
  /**
   * Check JS in Editor (Not in Editor Iframe or Preview/live)
   */
  isEditor$ = new BehaviorSubject<boolean>(
    !!(window.Editor && window.pageData)
  );
  public rootState$ = this._store.select(getOrderformState);
  public submitted$ = new BehaviorSubject(false);
  public globalSettings$: Observable<OrderformGlobalSettings> =
    this._store.select(getOrderformGlobalSettings);
  public selectedPlan$: Observable<OrderformSelectedPlan> = this._store.select(
    getOrderSelectedPaymentPlan
  );
  public config$: Observable<OrderformConfig> =
    this._store.select(getOrderformConfig);
  public order$: Observable<OrderformOrderState> = this._store.select(getOrder);
  public settings$: Observable<OrderformSettingsState> =
    this._store.select(getOrderformSettings);
  public validation$: Observable<OrderformValidationState> = this._store.select(
    getOrderformValidation
  );
  public messages$: Observable<OrderformOrderStateMessages> =
    this._store.select(getOrderformMessages);
  public pending$: Observable<boolean> = this._store
    .select(getOrderformPending)
    .pipe(map((data) => !!data));
  public requestPending$: Observable<boolean> = this.pending$.pipe(
    filter((pending) => !pending)
  );
  public paymentPlanList$ = this._store.select(getOrderformPaymentPlanList);
  public paymentPlanId$ = this._store.select(getOrderPaymentPlanId);
  public paymentUrl$ = this._store.select(getOrderPaymentUrl);
  public orderItems$ = this._store.select(getOrderItems);
  public mainProductId$ = this.config$.pipe(
    map((config) => +config.product_id)
  );
  public mainProduct$ = combineLatest([
    this.mainProductId$,
    this.orderItems$,
  ]).pipe(
    map(([id, items]) => {
      return items[id];
    })
  );

  public paymentLegalHint$ = this._store
    .select(getOrderformGlobalSettings)
    .pipe(map((settings) => settings.payment_legal_hint));

  public paymentPlanChanges$ = this._store
    .select(getOrder)
    .pipe(map((state) => state.payment_plan_changes));

  /**
   * Nach dem Absenden des Bestellformulars werden folgenden Events in dieser Reihenfolge geworfen:
   * - formValidation.emit()
   * Wenn die Validierung überall OK ist geht es weiter mit:
   * - beforeSubmit.emit()
   * Wenn hier auch ein false kommt wird das Orderform ans Backend geschickt
   * Wenn der Request zurück gekommen ist vom Backend mit einem OK, dann erfolgt:
   * - afterSubmit.emit()
   */
  public formValidation = new AdvancedEventEmitter<
    void,
    OrderFormValidationResult
  >();
  public beforeSubmit = new AdvancedEventEmitter<void, { valid: boolean }>();
  public afterSubmit = new AdvancedEventEmitter<void, any>();
  public submitFailed = new EventEmitter<void>();

  private _messagesShown = [];

  constructor(
    private _store: Store<OrderformPartialState>,
    private _ngZone: NgZone,
    private _toaster: ToasterService
  ) {
    /**
     * Logic to display messages via toast - with no repeats
     */
    // Wait until pending is done
    // default settings for toasts
    const settings: ToastItemConfig = {
      close: true,
    };

    /**
     * Disable Messaging in Editor
     * https://digistore.atlassian.net/browse/DS-9168
     */
    if (!this.isEditor$.value) {
      this.requestPending$.subscribe((pending) => {
        // Take messages
        this.order$.pipe(take(1)).subscribe((order) => {
          this._ngZone.runOutsideAngular(() =>
            setTimeout(() => {
              let tmpShow = [];
              (
                order.messages?.success || /* istanbul ignore next */ []
              ).forEach((message) => {
                /* istanbul ignore else */
                if (!this._messagesShown.includes(message)) {
                  this._toaster
                    .success(message)
                    .afterClosed()
                    .subscribe(() => this._removeMessageFromShownList(message));
                }
                tmpShow.push(message);
              });
              (order.messages?.infos || /* istanbul ignore next */ []).forEach(
                (message) => {
                  /* istanbul ignore else */
                  if (!this._messagesShown.includes(message)) {
                    this._toaster
                      .info(message)
                      .afterClosed()
                      .subscribe(() =>
                        this._removeMessageFromShownList(message)
                      );
                  }
                  tmpShow.push(message);
                }
              );
              (
                order.messages?.warnings || /* istanbul ignore next */ []
              ).forEach((message) => {
                /* istanbul ignore else */
                if (!this._messagesShown.includes(message)) {
                  this._toaster
                    .warn(message)
                    .afterClosed()
                    .subscribe(() => this._removeMessageFromShownList(message));
                }
                tmpShow.push(message);
              });
              (order.messages?.errors || /* istanbul ignore next */ []).forEach(
                (message) => {
                  /* istanbul ignore else */
                  if (!this._messagesShown.includes(message)) {
                    this._toaster
                      .error(message)
                      .afterClosed()
                      .subscribe(() =>
                        this._removeMessageFromShownList(message)
                      );
                  }
                  tmpShow.push(message);
                }
              );
              this._messagesShown = [...tmpShow];
              tmpShow = [];
            })
          );
        });
      });
    }
  }

  update$(): Observable<boolean> {
    return this.rootState$.pipe(
      take(1),
      switchMap((data) => {
        this._store.dispatch(
          OrderformActions.updateOrderformRequest({
            data: data.order.order,
          })
        );
        return this._store.select(getOrderformPending).pipe(
          filter((pending) => !pending),
          take(1)
        );
      })
    );
  }

  private _updateStore(data: InterfacePost) {
    this._store.dispatch(OrderformActions.setToOrderStore({ update: data }));
  }

  /***************************** Set data to store *************************/

  /**
   * Set data to store
   * @param data
   */
  setOrderStore(data: Partial<InterfacePost>) {
    this._updateStore(data);
  }

  /******************************** Requests ***********************/

  /**
   * Submit all data from store
   */
  validate$(): Observable<boolean> {
    return this.rootState$.pipe(
      take(1),
      switchMap((data) => {
        this._store.dispatch(
          OrderformActions.validateOrderformRequest({
            data: data.order.order,
          })
        );
        return this._store.select(getOrderformPending).pipe(
          filter((pending) => !pending),
          take(1)
        );
      })
    );
  }

  beforeSubmitOrderForm(): Observable<boolean> {
    return from(this.beforeSubmit.emit()).pipe(
      take(1),
      map((resultsBeforeSubmit) => {
        if (
          !resultsBeforeSubmit.reduce(
            (valid, result) => valid && result.valid,
            true
          )
        ) {
          this._store.dispatch(OrderformActions.beforeSubmitOrderformFailed());
          return false;
        }
        this._store.dispatch(OrderformActions.beforeSubmitOrderformSuccess());
        return true;
      })
    );
  }

  /**
   * Submit all data from store
   */
  submitOrderForm(): Observable<boolean> {
    this.submitted$.next(true);
    return this._store.select(getOrderformState).pipe(
      take(1),
      switchMap((data) => {
        // submit
        this._store.dispatch(
          OrderformActions.sendOrderFormRequest({
            data: data.order.order,
          })
        );
        return this.requestPending$.pipe(
          take(1),
          map(() => true)
        );
      })
    );
  }

  checkDoubleOrder(email: string): void {
    this._store.dispatch(OrderformActions.checkDoubleOrderRequest({ email }));
  }

  private _removeMessageFromShownList(message: string) {
    const messageIndex = this._messagesShown.findIndex(
      (item) => item === message
    );
    if (messageIndex < 0) {
      return;
    }
    this._messagesShown.splice(messageIndex, 1);
  }

  removeValidationError(postname: string) {
    this._store.dispatch(
      OrderformActions.removeValidationError({ field: postname })
    );
  }
}
